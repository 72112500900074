<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="py-5" elevation="8">
          <h1
            class="text-center primary--text text-h4"
            style="font-family: Kelly Slab !important"
          >
            Planos para o CPF/CNPJ {{ $store.state.cpf }}
          </h1>
          <h4 class="text-center">
            Data:
            {{
              new Date()
                .getDate()
                .toString()
                .padStart(2, "0")
            }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
            {{ new Date().getFullYear() }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" v-for="(item, i) in consolidado" :key="i">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ item.carteira }}
              </div>
              <v-list-item-title class="headline mb-1"
                >À Vista R$
                {{ item.item.totalValue | dinheiro }}</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">Contrato {{ item.contrato }}</div>
                <div class="text--primary">******</div>
              </v-list-item-subtitle>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="negociarConsol(item)"
              >
                Negociar
              </v-btn>
              <hr />
            </v-list-item-content>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" v-for="(item, i) in dividas" :key="i">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ item.carteira }}
              </div>
              <v-list-item-title class="headline mb-1"
                >Dívida de R$
                {{ item.valorDividaAPI | dinheiro }}</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">Contrato {{ item.contrato }}</div>
                <div class="text--primary">******</div>
              </v-list-item-subtitle>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="negociarDividaNaOi"
              >
                Negociar
              </v-btn>
              <hr />
            </v-list-item-content>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { api } from "@/conf/api";
export default {
  name: "PlanoOiNovaCPF",
  data() {
    return {
      dialog: false,

      consolidado: [],
      dividas: [],
    };
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
    this.consolidado = this.$store.state.divida_oi_nova;
    this.dividas = this.$store.state.divida_oi_nova2;
    if (this.consolidado.length == 0 && this.dividas.length == 0)
      return this.negociarDividaNaOi();
    this.consolidado.forEach((a) => {
      if (a.cgc == "33000118000190" || a.cgc == "33000118000183") {
        a.carteira = "BRT";
      }
      if (a.cgc == "04164616000158" || a.cgc == "04164616000160") {
        a.carteira = "Oi Móvel";
      }
      if (a.cgc == "33000118000179" || a.cgc == "33000118000181") {
        a.carteira = "Oi Fixo";
      }
      if (a.cgc == "05423963000111" || a.cgc == "05423963000114") {
        a.carteira = "Oi TV";
      }
    });
    this.dividas.forEach((a) => {
      if (a.cgc == "33000118000190" || a.cgc == "33000118000183") {
        a.carteira = "BRT";
      }
      if (a.cgc == "04164616000158" || a.cgc == "04164616000160") {
        a.carteira = "Oi Móvel";
      }
      if (a.cgc == "33000118000179" || a.cgc == "33000118000181") {
        a.carteira = "Oi Fixo";
      }
      if (a.cgc == "05423963000111" || a.cgc == "05423963000114") {
        a.carteira = "Oi TV";
      }
    });
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  methods: {
    async negociarDividaNaOi() {
      // window.location.href = "https://www.oi.com.br/negociacao/";
      window.open(
        "https://www.oi.com.br/negociacao",
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=800,height=700,fullscreen=yes"
      );
    },
    async negociarFatura(item) {
      this.dialog = true;
      console.log("item-fatura", item);
      let id = item.registerId;
      let meuEmail = window.prompt("Coloque um email...");
      if (!meuEmail) {
        window.alert("Você precisa colocar um email...");
        return;
      }
      await this.$store.dispatch("marcaEmail", meuEmail);
      let body = {
        register: id,
        tipoContato: "EMAIL",
        email: meuEmail,
        telefone: "",
      };
      try {
        let cgc_credor = this.$store.state.divida_atual.cpcgc;
        let cpf = this.$store.state.divida_atual.cpcpfcgc;
        let contrato = this.$store.state.divida_atual.contrato;
        let datarem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
        // /fatura/:cpf?/:contrato?/:cgc?/:datarem?/:sistema?
        let url = `telemanova/oi/fatura/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
        let resposta = await api.post(url, body);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", { item, resp });
      } catch (e) {
        console.log(e);
        return this.$router.push({ name: "ErroPage" });
      }
      // this.$store.dispatch("insereDividaOiNovaResp", dividaOiNova);
      this.dialog = false;
      this.$router.push({ name: "BolOiFatura" });
    },
    async negociarParc(item) {
      this.dialog = true;
      console.log("item-parc", item);
      let id = item.registerId;
      let meuEmail = window.prompt("Coloque um email...");
      if (!meuEmail) {
        window.alert("Você precisa colocar um email...");
        return;
      }
      await this.$store.dispatch("marcaEmail", meuEmail);
      let body = {
        register: id,
        tipoContato: "EMAIL",
        email: meuEmail,
        telefone: "",
      };
      try {
        let cgc_credor = this.$store.state.divida_atual.cpcgc;
        let cpf = this.$store.state.divida_atual.cpcpfcgc;
        let contrato = this.$store.state.divida_atual.contrato;
        let datarem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
        // /oferta/:cpf?/:contrato?/:cgc?/:datarem?/:sistema?
        let url = `telemanova/oi/oferta/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
        let resposta = await api.post(url, body);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", { item, resp });
      } catch (e) {
        console.log(e);
        return this.$router.push({ name: "ErroPage" });
      }
      //this.$router.push({ name: "PlanoOiNova2" });
      this.dialog = false;
      this.$router.push({ name: "BolOiParcelado" });
    },
    async negociarConsol(item) {
      this.dialog = true;
      console.log("item-a vista", item);
      let id = item.item.registerId;
      let emailemail = await api.get(`pessoa/email/${item.cpf}`);
      let email2 = emailemail.data.dados;
      console.log("email", email2.length);
      let meuEmail = "";
      if (email2.length > 0) {
        meuEmail = email2[0].dmmail;
      } else {
        meuEmail = window.prompt("Coloque um email...");
        if (!meuEmail) {
          window.alert("Você precisa colocar um email...");
          return;
        }
      }

      //----------------------------------------------
      await this.$store.dispatch("marcaEmail", meuEmail);
      let body = {
        register: id,
        tipoContato: "EMAIL",
        email: meuEmail,
        telefone: "",
      };
      try {
        let cgc_credor = item.cgc;
        let cpf = item.cpf;
        let contrato = item.contrato;
        let datarem = item.datarem.substr(0, 10);
        // /oferta/:cpf?/:contrato?/:cgc?/:datarem?/:sistema?
        let url = `telemanova/oi/oferta/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
        let resposta = await api.post(url, body);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", { item, resp });
        this.dialog = false;
        this.$router.push({ name: "BolOiAVistaCPF" });
        //let route2 = this.$router.resolve({ path: "/boloic2" });
        // window.open(route2.href);
      } catch (e) {
        console.log(e);
        return this.$router.push({ name: "ErroPage" });
      }
      //this.$router.push({ name: "PlanoOiNova2" });
    },
  },
};
</script>

<style></style>
