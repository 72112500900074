<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="py-5" elevation="8">
          <h1
            class="text-center primary--text text-h4"
            style="font-family: Kelly Slab !important"
          >
            Planos para o CPF/CNPJ {{ $store.state.cpf }}
          </h1>
          <h4 class="text-center">
            Data:
            {{
              new Date()
                .getDate()
                .toString()
                .padStart(2, "0")
            }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
            {{ new Date().getFullYear() }}
          </h4>
          <v-progress-circular
            v-if="showCirculo"
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" v-if="mostra1x">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ credor }}
              </div>

              <v-list-item-title class="headline mb-1"
                >A Vista</v-list-item-title
              >
              <span style="color:red;">Venc: {{ venc | dataF }}</span>
              <v-list-item-subtitle>
                <div class="text--primary">
                  Valor R$ {{ plano1x.valor | dinheiro }}
                </div>
                <div class="text--primary">*****</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn color="primary" @click="negociar('1x')">
              Negociar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostra2x">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ credor }}
              </div>
              <v-list-item-title class="headline mb-1"
                >Entrada + 1x</v-list-item-title
              >
              <span style="color:red;">Venc: {{ venc | dataF }}</span>
              <v-list-item-subtitle>
                <div class="text--primary">
                  Entrada de R$ {{ plano2x.valor | dinheiro }}
                </div>
                <div class="text--primary">
                  Mais 1 X de R$
                  {{ plano2x.valorP | dinheiro }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn color="primary" @click="negociar('2x')">
              Negociar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostra3x">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ credor }}
              </div>
              <v-list-item-title class="headline mb-1"
                >Entrada + 2x</v-list-item-title
              >
              <span style="color:red;">Venc: {{ venc | dataF }}</span>
              <v-list-item-subtitle>
                <div class="text--primary">
                  Entrada de R$ {{ plano3x.valor | dinheiro }}
                </div>
                <div class="text--primary">
                  Mais 2 X de R$
                  {{ plano3x.valorP | dinheiro }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn color="primary" @click="negociar('3x')">
              Negociar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostra5x">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ credor }}
              </div>
              <v-list-item-title class="headline mb-1"
                >Entrada + 4x</v-list-item-title
              >
              <span style="color:red;">Venc: {{ venc | dataF }}</span>
              <v-list-item-subtitle>
                <div class="text--primary">
                  Entrada de R$ {{ plano5x.valor | dinheiro }}
                </div>
                <div class="text--primary">
                  Mais 4 X de R$
                  {{ plano5x.valorP | dinheiro }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn color="primary" @click="negociar('5x')">
              Negociar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { api } from "@/conf/api";
import {
  pegarParcXML2,
  pegarBoletoXML,
  // datavencimento,
} from "@/conf/utilitario";

export default {
  name: "PlanoAlgarOferta",
  data() {
    return {
      credor: "",
      divida: {},
      mostra1x: false,
      mostra2x: false,
      mostra3x: false,
      mostra5x: false,
      showCirculo: false,
      venc: "",
      plano1x: {
        ida: "",
        p: {},
        ps: "",
        valor: 0,
        parcelas: 0,
        codbarras: "",
        valorP: 0,
      },
      plano2x: {
        ida: "",
        p: {},
        ps: "",
        valor: 0,
        parcelas: 1,
        codbarras: "",
        valorP: 0,
      },
      plano3x: {
        ida: "",
        p: {},
        ps: "",
        valor: 0,
        parcelas: 2,
        codbarras: "",
        valorP: 0,
      },
      plano5x: {
        ida: "",
        p: {},
        ps: "",
        valor: 0,
        parcelas: 4,
        codbarras: "",
        valorP: 0,
      },
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  async beforeMount() {
    this.pegarPlanosAlgar();
  },
  methods: {
    acertaMoney(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    acertaData(d) {
      let dia = d.substr(0, 2);
      let mes = d.substr(3, 2);
      let ano = d.substr(6, 4);
      return `${ano}-${mes}-${dia}`;
    },
    async negociar(plano) {
      let fraseaqui = "Você tem certeza que deseja negociar?";
      try {
        if (confirm(fraseaqui) == false) return;
        await this.$store.dispatch("marcaPacoteAtual", "E");

        this.showCirculo = true;

        if (plano == "1x") {
          //
          await this.$store.dispatch("marcaNegocAtual", this.plano1x);
          await this.$store.dispatch("quantasParcelasFaltam", 0);
        }
        if (plano == "2x") {
          //
          await this.$store.dispatch("marcaNegocAtual", this.plano2x);
          await this.$store.dispatch("quantasParcelasFaltam", 1);
        }
        if (plano == "3x") {
          //
          await this.$store.dispatch("marcaNegocAtual", this.plano3x);
          await this.$store.dispatch("quantasParcelasFaltam", 2);
        }
        if (plano == "5x") {
          //
          await this.$store.dispatch("marcaNegocAtual", this.plano5x);
          await this.$store.dispatch("quantasParcelasFaltam", 4);
        }
        let emailemail = await api.get(`pessoa/email/${this.cpf}`);
        let email2 = emailemail.data.dados;
        console.log("email", email2.length);
        let meuEmail = "";
        if (email2.length > 0) {
          meuEmail = email2[0].dmmail;
        } else {
          meuEmail = window.prompt("Coloque um email...");
          if (!meuEmail) {
            window.alert("Você precisa colocar um email...");
            return;
          }
        }
        await this.$store.dispatch("marcaEmail", meuEmail);
        // this.$router.push(this.$store.state.divida_atual.cpcgc);
        //------------confirmar negoc-------------------------------
        let p = this.$store.state.negoc_atual.ida;
        //console.log("envioParc", p);
        let res1 = await api.post("algar/confirmarparc", { str: p });
        //console.log("confirma", res1.data);
        if (!res1.data.objResultado.XML.RETORNO) {
          return this.$router.push("erroPage");
        }

        let acordo = res1.data.objResultado.XML.RETORNO.CODPARCE._text;
        await this.$store.dispatch("guardaNumAcordo", acordo);
        let cpf = this.$store.state.cpf;
        let venc = this.$store.state.divida_atual.venc; //datavencimento();
        await this.$store.dispatch("marcaVencAtual", venc);
        console.log(acordo, cpf, venc);

        this.$store.dispatch("marcaNegocAlgar", res1.data); // guarda a volta do confirma parcelamento
        //let confirmaParc = this.$store.state.negoc_atual_algar;
        //console.log("negocalgar", confirmaParc);

        let strXML = pegarBoletoXML(cpf, acordo, venc, "000");
        //console.log("strBoleto", strXML.objXML);

        let res2 = await api.post("algar/emitirboleto", { str: strXML.objXML });

        //console.log(res2.data);
        this.$store.dispatch("guardaBoleto", res2.data);

        //------------confirmar negoc-------------------------------
        this.showCirculo = false;
        // this.$router.push("71208516000174");
        this.$router.push({ name: "BolAlgarOferta" });
      } catch (e) {
        console.log(e);
        this.$store.commit("showMessage", {
          text: "Erro de boleto! Ligue pra Easycob",
          color: "warning",
          show: true,
        });
        return;
      }
    },
    async pegarPlanosAlgar() {
      try {
        this.showCirculo = true;
        let divida = this.$store.state.divida_atual;
        //let valorDivida = divida.cpsaldev;
        //console.log("rr", valorDivida);

        let cpf = divida.cpcpfcgc;
        let contrato = divida.contrato;
        let vencimento = divida.venc;
        let pegaDivida = await api.get(`algar/consultar/${cpf}/${contrato}`);
        console.log('div',pegaDivida)
        if (pegaDivida.data.objMeu.xml.prest.length == 0) {
          return this.$router.push("erroPage");
        }

        let str = pegarParcXML2(
          pegaDivida.data.objMeu,
          0,
          "0,00",
          "S",
          "90,00", // porcentagem
          vencimento
        );
        //console.log("res1", str);
        let res1 = await api.post("algar/calcularparc", { str: str.objXML });

        let oferta1 = res1.data;
        //console.log("oferta1", oferta1);
        if (
          oferta1.objResultado.XML.RETORNO &&
          oferta1.objResultado.XML.RETORNO.CODIGO._text == "00"
        ) {
          this.plano1x.ps = oferta1.resultado;
          this.plano1x.p = oferta1.objResultado;
          this.plano1x.valor = oferta1.objResultado.XML.PARCELAS.PAGO._text
            .replace(".", "")
            .replace(",", ".");

          this.venc = this.acertaData(
            oferta1.objResultado.XML.PARCELAS.VENCIMENTO._text
          );
          // this.venc = divida.venc;
          this.plano1x.ida = str.objXML;
        }
        console.log("valoresss", this.venc, this.plano1x.ida);

        //---------------------------------------------------------------------------
        /*
        let valorEntrada = valorDivida / 2;
        //console.log(valorEntrada);

        str = pegarParcXML(
          pegaDivida.data.objMeu,
          1,
          this.acertaMoney(valorEntrada)
        );
        let res2 = await api.post("algar/calcularparc", { str: str.objXML });

        let oferta2 = res2.data;
        console.log("2", oferta2);
        if (
          oferta2.objResultado.XML.RETORNO &&
          oferta2.objResultado.XML.RETORNO.CODIGO._text == "00"
        ) {
          this.plano2x.ps = oferta2.resultado;
          this.plano2x.p = oferta2.objResultado;
          this.plano2x.valor = oferta2.objResultado.XML.PARCELAS[0].PRINCIPAL._text.replace(".", "")
            .replace(",", ".");
          );
          this.venc = this.acertaData(
            oferta2.objResultado.XML.PARCELAS[0].VENCIMENTO._text
          );
          this.plano2x.valorP = oferta2.objResultado.XML.PARCELAS[1].PRINCIPAL._text.replace(".", "")
            .replace(",", ".");
          this.plano2x.ida = str.objXML;
        }
        //------------------------------------------------------------------------------------
        //valorEntrada = valorDivida / 3;
        //console.log(valorEntrada);

        str = pegarParcXML(
          pegaDivida.data.objMeu,
          2,
          this.acertaMoney(valorEntrada)
        );
        let res3 = await api.post("algar/calcularparc", { str: str.objXML });

        let oferta3 = res3.data;
        console.log("3", oferta3);
        if (
          oferta3.objResultado.XML.RETORNO &&
          oferta3.objResultado.XML.RETORNO.CODIGO._text == "00"
        ) {
          this.plano3x.ps = oferta3.resultado;
          this.plano3x.p = oferta3.objResultado;
          this.plano3x.valor = oferta3.objResultado.XML.PARCELAS[0].PRINCIPAL._text.replace(".", "")
            .replace(",", ".");
          this.venc = this.acertaData(
            oferta3.objResultado.XML.PARCELAS[0].VENCIMENTO._text
          );
          this.plano3x.valorP = oferta3.objResultado.XML.PARCELAS[1].PRINCIPAL._text.replace(".", "")
            .replace(",", ".");
          this.plano3x.ida = str.objXML;
        }
        //----------------------------------------------------------------------------------

        //console.log(valorEntrada);

        str = pegarParcXML(
          pegaDivida.data.objMeu,
          4,
          this.acertaMoney(valorEntrada)
        );
        let res5 = await api.post("algar/calcularparc", { str: str.objXML });
        //console.log(res5);
        let oferta5 = res5.data;
        console.log("5", oferta5);
        if (
          oferta5.objResultado.XML.RETORNO &&
          oferta5.objResultado.XML.RETORNO.CODIGO._text == "00"
        ) {
          this.plano5x.ps = oferta5.resultado;
          this.plano5x.p = oferta5.objResultado;
          this.plano5x.valor = oferta5.objResultado.XML.PARCELAS[0].PRINCIPAL._text.replace(".", "")
            .replace(",", ".");
          this.venc = this.acertaData(
            oferta5.objResultado.XML.PARCELAS[0].VENCIMENTO._text
          );
          this.plano5x.valorP = oferta5.objResultado.XML.PARCELAS[1].PRINCIPAL._text.replace(".", "")
            .replace(",", ".");
          this.plano5x.ida = str.objXML;
        }
        */

        if (this.plano1x.ps != "") {
          this.mostra1x = true;
        }
        if (this.plano2x.ps != "") {
          this.mostra2x = true;
        }
        if (this.plano3x.ps != "") {
          this.mostra3x = true;
        }
        if (this.plano5x.ps != "") {
          this.mostra5x = true;
        }
        if (
          !this.mostra1x &&
          !this.mostra2x &&
          !this.mostra3x &&
          !this.mostra5x
        ) {
          this.$router.push("erroPage");
        }
        this.showCirculo = false;
        //================================================================
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>

<style></style>
